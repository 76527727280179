import React, { useState, useEffect } from 'react';

import { useInterval } from '../../utils/hooks';

const word = 'lex.';

function Landing() {
  const [text, setText] = useState('');
  const [pauseInterval, setPauseInterval] = useState(false);

  const updateText = () => {
    const char = word[text.length];
    console.log(text);

    if (char) {
      setText(text + char);
    } else {
      setPauseInterval(true);
    }
  };

  useInterval(updateText, pauseInterval ? null : 1000);

  return <h2>{text}</h2>;
}

export default Landing;
