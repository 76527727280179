import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Landing from './pages/Landing';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/notes'>Notes</Link>
            </li>
            <li>
              <Link to='/users'>Users</Link>
            </li>
          </ul>
        </nav> */}
      </div>
      <div className='App'>
        <header className='App-header'>
          <Switch>
            <Route path='/notes'>
              <Notes />
            </Route>
            <Route path='/users'>
              <Users />
            </Route>
            <Route path='/'>
              <Landing />
            </Route>
          </Switch>
        </header>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>alexei</h2>;
}

function Notes() {
  return <h2>Notes</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
